.Test .lander {
  padding: 80px 0;
  text-align: center;
}

.Test .lander h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.Test .lander p {
  color: #999;
}
