.Home .lander {
  padding: 80px 0;
  text-align: center;
}

.Home .lander h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.Home .lander p {
  color: #999;
}

.NotFound {
  padding-top: 100px;
  text-align: center;
}

.Test .lander {
  padding: 80px 0;
  text-align: center;
}

.Test .lander h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.Test .lander p {
  color: #999;
}

@media all and (min-width: 480px) {
  .FindDiverPanel {
    padding: 60px 0;
  }

  .FindDiverPanel form {
    margin: 0 auto;
    max-width: 320px;
  }
}

.FindDiverPanel form span.help-block {
  font-size: 14px;
  padding-bottom: 10px;
  color: #999;
}

.App {
  margin-top: 15px;
}

.App .navbar-brand {
  font-weight: bold;
}

.App {
  margin-top: 15px;
}

.App .navbar-brand {
  font-weight: bold;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

